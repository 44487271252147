<template>
    <div class="bg" v-if="idea != null && data.length > 0" @click="openPopup(true)">
        <!-- <img :src="data[ideaIndex - 1].img" v-if="data[ideaIndex - 1] != null" /> -->
        <!-- <img :src="showPrevBg" v-if="showPrevBg != null" /> -->
        <div class="bg" id="bgImg">
            <img :src="idea.img" />
        </div>
        <img :src="data[ideaIndex + 1].img" v-if="data[ideaIndex + 1] != null" class="hidden" />
    </div>
    <List el="md p-md" id="ratingPage" v-if="idea != null && ideaIndex < data.length">
      <List el="xsm">
        
        <List el="column-between xsm" style="align-items:center;">
            <List el="column xxsm" style="height:35px;align-items:center;">
                <!-- <button el="flat-btn" @click="prevIdea" v-show="ideaIndex != 0">
                    <span class="material-icons">west</span>
                    <span>Prev</span>
                </button> -->
                <span class="material-icons iconBtn" @click="prevIdea" v-show="ideaIndex != 0">west</span>
                <span class="material-icons iconBtn" @click="addNote">edit_note</span>
            </List>
            <Text el="h4">{{ideaIndex+1}}/{{data.length}}</Text>
        </List>
        <Text el="h2">{{idea.label}}</Text>
        
        <div v-show="!idea.interested">
            <div el="status-error">not interested</div>
        </div>
        <Text v-if="idea.description != '' && idea.description != null">{{idea.description}}</Text>
        
        <List el="column-stretch" class="stars" v-show="idea.interested">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7575CF" :class="{'w-6':true, 'h-6':true, star:true, active: rating == i, before: i < rating, after: i > rating}" v-for="i in 5" :key="i" @click="setRating(i)">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
            </svg>

        </List>
      </List>
      <div el="list-column-stretch xxsm">
        <Btn @click="interested(false)" el="2ry" v-if="idea.interested">Not Interested</Btn>
        <Btn @click="interested(true)" el="2ry" v-else>Interested</Btn>
        <Btn @click="nextIdea" v-if="nextBtnStatus == null">Next</Btn>
        <Btn v-else disabled>loading...</Btn>
      </div>
    </List>
    <List el="md p-lg" id="thankYouPage" v-if="section == 'thank you'">
      <List el="xsm">
        <Text el="h2">Thank You</Text>
        <Text>Can't wait to try this stuff together!!! I hope you enjoyed all the periwinkle, lol.</Text>
        <Btn @click="startOver">Start Over</Btn>
      </List>
    </List>
    <img :src="idea.img" id="popup" v-if="idea != null && data.length > 0" @click="openPopup(false)">
  </template>
  
  <script setup>
    import {ref, nextTick, onMounted, computed } from 'vue'
    import gsap from 'gsap'
    import dialog from '@/components/dialog.js';
    // import router from "@/router";
    // const to = (path)=>{
    //   router.push(path);
    // }

    const section = ref(null);
    const rating = ref(0);
    const nextBtnStatus = ref(null);
    const showPrevBg = ref(null);
  
    const setRating = async (val)=>{
        let newVal = true;
        if(rating.value == val){
            newVal = false;
            rating.value = 0;
        }else{
            rating.value = val;
        }
        await nextTick();
        let tl = gsap.timeline({});
        if(newVal){
            tl.to('.star:not(.after)',{scale:1.4,stagger:0.1, duration:0.2, ease: "sine.out"});
            tl.addLabel('enlargeStar');
            tl.to('.star:not(.after)',{scale:1,stagger:0.1, duration:1, delay:0.2, ease: "elastic.out(1, 0.3)"}, "<");
            tl.to('.star.active',{rotation:144, y:-32, duration:1, ease: "elastic.out(1, 0.5)"},"enlargeStar");
            tl.to('.star.active',{rotation:0, y:0, duration:1, ease: "elastic.out(1, 0.5)"});
        }else{
            tl.to('.star',{scale:1.4,stagger:0.1, duration:0.2, ease: "sine.out"});
            tl.to('.star',{scale:1,stagger:0.1, duration:1, delay:0.2, ease: "elastic.out(0.5, 0.3)"}, "<");
        }
        tl.play();
    }

    const openPopup = async (val)=>{
        await nextTick();
        if(val == true){
            gsap.to('#popup',{autoAlpha:1, duration:0.4, ease: "sine.out"});
        }else{
            gsap.to('#popup',{autoAlpha:0, duration:0.4, ease: "sine.out"});
        }
    }

    const data = ref([]);

    const ideaIndex = ref(0);

    const nextIdea = async () =>{
        showPrevBg.value = `${idea.value.img}`;
        nextBtnStatus.value = 'loading';
        await saveRating();
        nextBtnStatus.value = null;
        ideaIndex.value++;
        getRating();
        if(ideaIndex.value >= data.value.length){
            section.value = 'thank you';
        }
        animateBgImg();
    }

    const prevIdea = ()=>{
        ideaIndex.value--;
        getRating();
    }

    const startOver = ()=>{
        ideaIndex.value = 0;
        section.value = null;
        startAnimation();
        getRating();
    }

    const interested = async (val)=>{
        idea.value.interested = val;
        // let result = await postAPI('/ideas/update', idea.value);
        // console.log(result);
    }

    const getRating = async ()=>{
        if(idea.value != null){
            await nextTick();
            rating.value = idea.value.rating;
            let tl = gsap.timeline({});
            tl.to('.star',{scale:1.4,stagger:0.1, duration:0.2, ease: "sine.out"});
            tl.to('.star',{scale:1,stagger:0.1, duration:1, delay:0.2, ease: "elastic.out(0.5, 0.3)"}, "<");
        }
    }

    const saveRating = async ()=>{
        idea.value.rating = rating.value;
        let result = await postAPI('/ideas/update', idea.value);
        console.log(result);
    }

    const addNote = async ()=>{
        let note = idea.value.note || '';
        const confirm = await dialog.title('Add Note').defaultInput(note).okText('Save').prompt(`Save notes to "${idea.value.label}"`);
        if(confirm === false) return;
        idea.value.note = confirm;
        let result = await postAPI('/ideas/update', idea.value);
        console.log(result);
    }

    const startAnimation = async ()=>{
        await nextTick();
        let tl = gsap.timeline({delay:0.5});
        tl.fromTo('#ratingPage',{opacity:0, y:-50, scale:0}, {opacity:1, y:0, scale:1,duration:2.5, ease: "elastic.out(1, .8)"});
  }
    const animateBgImg = async ()=>{
        gsap.set('#bgImg',{opacity:0});
        await nextTick();
        gsap.fromTo('#bgImg',{opacity:0, y:-50, scale:1.2}, {opacity:1, y:0, scale:1,duration:3, ease: "elastic.out(1, .8)"});
        showPrevBg.value = null;
  }

    const addIdea = async ()=>{
        // data.value.forEach(async (item)=>{
        //         let result = await postAPI('/ideas/add', item);
        //         console.log(result);
        // });
    }

    const postAPI = async (path, json)=>{
        const res = await fetch(`https://date-ideas.burkedesigns.workers.dev${path}`,{
        // const res = await fetch(`https://date-ideas.burkedesigns.workers.dev/ideas/add`,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: json}),
        });
        
        const result = await res.json();
        return result;
    }
    const getAPI = async (path)=>{
        const res = await fetch(`https://date-ideas.burkedesigns.workers.dev${path}`);
        const result = await res.json();
        return result;
    }
    console.log(postAPI);

  const idea = computed(() => {
    return data.value[ideaIndex.value];
  });



    onMounted(async ()=>{
        gsap.set('#popup',{autoAlpha:0});

        const d = new Date();
        let time = d.getTime();
        let result = await getAPI('/ideas/list?t='+time);
        let newData = [];
        result.list.forEach(async (item) => {
            let val = JSON.parse(item.metadata.value);
            // if(val.id != null){
            //     newData.push(val);
            // }else{
            //     newData.push({id:item.name, ...val});
            // }
            newData.push({id:item.name, ...val});
        });
        data.value = newData;
                // console.log(result.list);

        getRating();
        startAnimation();
        addIdea();
    })
  
  </script>
  <style lang="scss" scoped>
  // [el^="footer"] {
  //   --bg-color: green;
  // }
  #ratingPage,
  #thankYouPage{
    max-width: 500px;
    background-color: hsl(240deg 100% 90% / 95%);
    border-radius: 24px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 7%);
    position: fixed;
    bottom: 20px;
    width: calc(100% - 16px);
    left: 50%;
    transform: translateX(-50%);
    opacity:0;
}
#thankYouPage{
    opacity: 1;
}
.stars{
    user-select:none;
}
.stars .star{
    height:55px;
    color:transparent;
    transition:0.3s color;
    cursor: pointer;
}
.stars .star:hover{
    color:#5453A6;
}
.stars .star.active,
.stars .star.before{
    height:55px;
    color:#7575CF;
}
img{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    mix-blend-mode: luminosity;
    display: block;
    position:absolute;
    // opacity: 0.65;
}
.bg{
    background-color: #CCCCFF;
    height:100vh;
    width: 100%;
    overflow: hidden;
    position:absolute;
}
.hidden{
    opacity:0;
}
#popup{
    opacity:0;
    mix-blend-mode: initial;
    object-fit: contain;
    background-color: #CCCCFF;
    // display: none;
}
.iconBtn{
    font-size:40px;
    color:#7575CF;
    transition:0.2s;
    cursor: pointer;
}
.iconBtn:hover{
    color:#5453A6;
}
  </style>
  